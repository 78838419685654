<template>
  <div>
    <p>Location</p>
    <!-- <locationDrop class="locationDropDisp" :startingId="'0'" :states="locationStates" :enabled="locationDropDownEnabled"
                  @selection="locationSelected"></locationDrop> -->

    <div class="container">
      <div class="custom-select">  
        <select :disabled="!locationDropDownEnabled" @change="locationSelected($event.target.value)" v-model="selectedLocation">
          <option  v-for="(item, index) in locationStates" :key="index" :value="item">{{ item }}</option>
        </select>
      </div>
    </div>
  </div>

  <div>
    <p>Vehicle</p>
    <!-- <vehicleDrop class="vehicleDropDisp" :startingId="'0'" :states="vehicleStates" :enabled="vehicleDropDownEnabled"
                 @selection="vehicleSelected"></vehicleDrop> -->

    <div class="container">
      <div class="custom-select">  
        <select :disabled="!vehicleDropDownEnabled" @change="vehicleSelected($event.target.value)" v-model="selectedMachine">
          <option v-for="(item, index) in vehicleStates" :key="index" :value="item">{{ item }}</option>
        </select>
      </div>
    </div>

  </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router';
const router = useRouter();
const route = useRoute();

import {ref, defineProps, defineEmits, toRefs, onMounted} from 'vue';
import { useStore } from 'vuex';
const store = useStore();
// import LocationDrop from '../../components/dropdowns/dropdown1.vue';
// import VehicleDrop from '../../components/dropdowns/dropdown1.vue';

const props = defineProps({src: String});
const {src} = toRefs(props);
const emit = defineEmits(['selected']);

// console.log("VTYPE", src);

let currentDropletId = "";
let locationDropDownEnabled = ref(false);
let vehicleDropDownEnabled = ref(false);

let locationStates = ref({});
let vehicleStates = ref({});

let selectedLocation = ref("");
let selectedMachine = ref("");
let machine = ref(store.getters.getLocation);
let locations = {
  "LCL1": {
    id: 110,
    loc: "NGO",
  },
  "LCL3": {
    id: 111,
    loc: "NGO",
  },
  "MTS1": {
    id: 100,
    loc: "NGO",
  },
  "MTS4": {
    id: 101,
    loc: "NGO",
  },
  "LCL2": {
    id: 210,
    loc: "TAR",
  },
  "LCL4": {
    id: 211,
    loc: "TAR",
  },
  "MTS2": {
    id: 200,
    loc: "TAR",
  },
  "MTS3": {
    id: 201,
    loc: "TAR",
  },
  "LCL5": {
    id: 310,
    loc: "IAB",
  },
  "LCL7": {
    id: 311,
    loc: "IAB",
  },
  "MTS5": {
    id: 300,
    loc: "IAB",
  },
  "MTS7": {
    id: 301,
    loc: "IAB",
  },
  "LCL6": {
    id: 410,
    loc: "PAE",
  },
  "LCL8": {
    id: 411,
    loc: "PAE",
  },
  "MTS6": {
    id: 400,
    loc: "PAE",
  },
  "MTS9": {
    id: 401,
    loc: "PAE",
  },
  "LCL9": {
    id: 500,
    loc: "CHS",
  },
  "LCL10": {
    id: 501,
    loc: "CHS",
  },
  "MTS8": {
    id: 510,
    loc: "CHS",
  },
  "MTS10": {
    id: 511,
    loc: "CHS",
  }
};

onMounted(() => {
  console.log(store.getters.getLocation, 'store.getters.getLocation')
  let i = store.getters.getLocation;
  if (store.getters.getLocation != '') {
    if (route.name === 'LclMonitoring' && i == '110'
      || route.name === 'LclMonitoring' && i == '501'
      || route.name === 'LclMonitoring' && i == '210'
      || route.name === 'LclMonitoring' && i == '111'
      || route.name === 'LclMonitoring' && i == '211'
      || route.name === 'LclMonitoring' && i == '310'
      || route.name === 'LclMonitoring' && i == '410'
      || route.name === 'LclMonitoring' && i == '311'
      || route.name === 'LclMonitoring' && i == '411'
      || route.name === 'LclMonitoring' && i == '500') {
      openMachine();
    } else if (route.name === 'MtsMonitoring' && i == '100'
      || route.name === 'MtsMonitoring' && i == '511'
      || route.name === 'MtsMonitoring' && i == '200'
      || route.name === 'MtsMonitoring' && i == '201'
      || route.name === 'MtsMonitoring' && i == '101'
      || route.name === 'MtsMonitoring' && i == '300'
      || route.name === 'MtsMonitoring' && i == '400'
      || route.name === 'MtsMonitoring' && i == '301'
      || route.name === 'MtsMonitoring' && i == '510'
      || route.name === 'MtsMonitoring' && i == '401') {
      openMachine();
    }
  }
});
function openMachine() {
  console.log(machine.value,'machine')
  let machineID = locations[machine.value].id;
  selectedMachine.value = machine.value;
  selectedLocation.value = locations[machine.value].loc;
  locationSelected(selectedLocation.value);
  vehicleSelected(machineID);
};
const getLocationsUrl = "https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/getTags.php?locSel";
let getVehicleUrl = "https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/getTags.php?dropSel&type=" + src.value + "&location=";

fetch(getLocationsUrl, {
  headers: {'Content-type': 'application/json'},
}).then((res) => res.json()).then((response) => {
  locationStates.value = response;
  locationDropDownEnabled.value = true;
}).catch((error) => {
  console.log("LOCATION FETCH ERROR " + error);
});

let currentlySelectedLocationId = "";

function locationSelected(id) {
  vehicleStates.value = {};
  currentlySelectedLocationId = id;
  fetch(getVehicleUrl + id, {
    headers: {'Content-type': 'application/json'},
  }).then((res) => res.json()).then((response) => {
    vehicleStates.value = response;
    vehicleDropDownEnabled.value = true;
  }).catch((error) => {
    console.log("VEHICLE FETCH ERROR " + error);
  });
}

function vehicleSelected(id) {
  currentDropletId = id;
  emit('selected', {
        "droplet": currentDropletId,
        "location": locationStates.value[currentlySelectedLocationId]
      }
  );
  // store.state.operationsLocation = id;
}
</script>

<style lang="scss" scoped>
.container {
  position:relative;
  contain: layout;
}
.custom-select {
  height: 40px;
  border-radius: 10px;
	position: relative;
  width: 100%;
  @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
    width: auto;
  }
  select{
    border: 1px solid red;
  }
  select {
    appearance: none;
    /*  safari  */
    -webkit-appearance: none;
    /*  other styles for aesthetics */
    text-indent: 12px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #BCBCBC;
    border-radius: 10px;
    color: #252525;
    cursor: pointer;
    height: 36px;
    font-size: 1rem;
    font-weight: 400;
    outline: none;
  }
}
.custom-select::before{
  content: "";
  position: absolute;
  right: 1rem;
  pointer-events: none;
}

.custom-select::before {
	height: 24px;
	width: 24px;
	top: 50%;
	transform: translateY(-50%);
	right: 12px;
	background-image: url(../../../public/img/arr_down.svg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center left;
}
</style>